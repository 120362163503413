import React from 'react'
import { PayrollBanner } from './PayrollBanner'
import { PayrollManagement } from './PayrollManagement'
import { PayrollSoftwares } from './PayrollSoftwares';
import "./payroll.css";
import { FooterContactBox } from '../FooterContactBox';
import { useModal } from '../ContactForm';

export const PayrollPage = () => {
  const { modalAction } = useModal();
  return (
      <div>
          <PayrollBanner />
          <PayrollManagement />
      <PayrollSoftwares />
      <FooterContactBox>
        <div className='p-4 lg:p-0 lg:w-2/3'>
          <h5 className='font-[700] text-[30px] lg:text-[38px]'>Let us connect for communication</h5>
          <p className='font-[400] text-[18px] py-3'>An all-inclusive payroll management system from Talntworx enables quick payroll from a single window. Reach out to us for more information.</p>
          <button className='bg-[#0361FE] text-white py-[11px] px-[25px] rounded-[48px] mt-5' onClick={modalAction}>Contact now</button>

        </div>
      </FooterContactBox>
    
    </div>
  )
}
