import React from 'react';
import { payrollSoftwareContent } from '../../data/payrollContent';
import { FeatureBox } from '../FeatureBox';

export const PayrollSoftwares = () => {
    // console.log(payrollSoftwareContent);
  return (
      <section className='w-11/12 mx-auto'>
          <h1 className='font-[700] text-[30px] lg:text-[45px] lg:text-center py-16'>{payrollSoftwareContent.title.normal}<span className='text-[#0361FE]'>{payrollSoftwareContent.title.coloured}</span>{payrollSoftwareContent.title.normalContinuation}</h1>

          <div>
              {
                  payrollSoftwareContent.data.map((softwareData, index) => {
                    //   console.log(softwareData)
                      return <FeatureBox key={index} {...softwareData} />
                  })
              }
          </div>
    </section>
  )
}
